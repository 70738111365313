<template>
  <div class="">
    <el-form :inline="true">
      <el-form-item label="机构" v-if="type == 'manage'">
        <mechanismSelect :mecid.sync="change_mechanism"></mechanismSelect>
      </el-form-item>
      <el-form-item>
        <el-button @click="getList(1)" type="success">查询</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #student="{ row }">
        <div class="flex al-c" v-if="row.create_user_info">
          <p>创建人:</p>
          <div class="stu-item" @click="toUserDetails(row.create_user_info)">
            <img :src="row.create_user_info.avatar" alt="" class="stu-img" />
            <p class="stu-name">{{ row.create_user_info.nick_name }}</p>
          </div>
        </div>
        <div class="flex students" v-if="row.map.studyCards.length > 0">
          <p>班级学员:</p>
          <div
            class="stu-item"
            @click="toUserDetails(stu)"
            v-for="stu in row.map.studyCards"
            :key="stu.id"
          >
            <img :src="stu.avatar" alt="" class="stu-img" />
            <p class="stu-name">
              {{ stu.map.children ? stu.map.children.name : stu.nick_name }}
            </p>
          </div>
        </div>
      </template>

      <template #handle="{ row }">
        <el-button size="small" type="warning" @click="studentMange(row)"
          >学员管理</el-button
        >
        <el-button
          size="small"
          v-if="row.need_id"
          type="success"
          @click="editCom(row)"
          >编辑订单</el-button
        >
        <el-button size="small" v-else type="primary" @click="createCom(row)"
          >生成订单</el-button
        >
        <el-button size="small" type="danger" @click="cancelClass(row)"
          >取消班级</el-button
        >
      </template>
    </auto-table>

    <el-dialog title="订单" :visible.sync="dialogVisible" width="35%">
      <el-form>
        <el-form-item label=""  v-if="mode == 'add'">
        <div v-html="priceInfo"></div>
        </el-form-item>
        <el-form-item label="订单总金额" required>
          <el-input
            v-model="form.amount"
            placeholder="订单总金额(教练总收益)"
          ></el-input>
        </el-form-item>
        <el-form-item label="需求名称" required>
          <el-input v-model="form.title" placeholder="订单需求名称"></el-input>
        </el-form-item>
        <el-form-item label="分成方式">
          <el-radio-group v-model="settlement_type">
            <el-radio :label="1">固定金额</el-radio>
            <el-radio :label="2">比例分成</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="tips" v-if="curCourse.id">结算说明：</div>
        <div class="tips" v-if="curCourse.id">
          请根据实际结算需要填写，如不需结算的填0，<span class="tips" v-if="curCourse.id">
              参考结算金额{{
                curCourse.settlement_cash * curClass.student_count
              }}元
            </span>
        </div>

        <div class="row" v-if="settlement_type == 1" required>
          <el-form-item label="教练单节结算价" required>
            <el-input
              v-model="form.coach_settlement_cash"
              placeholder="单节课教练结算价 如80"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="机构单节结算价">
            <el-input
              v-model="form.mechanism_settlement_cash"
              placeholder="单节课机构结算价 如80"
            ></el-input>
          </el-form-item> -->
        </div>
        <div class="row" v-if="settlement_type == 2">
          <el-form-item label="单节结算价" required>
            <el-input
              v-model="form.settlement_cash"
              placeholder="单节课单节结算价 如300"
            ></el-input>
          </el-form-item>
          <el-form-item label="教练结算比例" required>
            <el-input
              v-model="form.coach_profit"
              placeholder="单节课结算价比例 如0.7结算270"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="机构结算比例" required>
            <el-input
              v-model="form.mechanism_profit"
              placeholder="单节课结算价比例 如0.7"
            ></el-input>
          </el-form-item> -->
        </div>
        <div class="row">
          <el-form-item label="上课时间段">
            <el-input
              v-model="form.time_slot"
              placeholder="如：周末、上午10;00..."
            ></el-input>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-date-picker
              v-model="form.start_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker
              v-model="form.end_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期"
              @change="endtimeChange"
            ></el-date-picker>
          </el-form-item>
        </div>

        <div>
          
          <el-form-item label="城市" required>
            <el-cascader
              v-model="cityList"
              :options="cityoptions"
              :props="cityProps"
              @change="cityChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="上课地点">
            <el-input v-model="form.address">
              <addr-select
                slot="append"
                @getform="changeAddr"
                type="icon"
              ></addr-select>
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="上课机构">
            <div class="flex">
              <mechanismSelect
                ref="mec"
                :mecid.sync="form.mechanism_id"
                :placeholder="form.mechanism_id || ''"
                @change="changeMec"
              ></mechanismSelect>
              <el-button type="text" @click="form.mechanism_id = 0"
                >取消</el-button
              >
            </div>
          </el-form-item> -->
        </div>
      </el-form>
      <el-tag v-if="form.status == 5">已接单</el-tag>
      <el-tag v-if="form.status == 6">已接单</el-tag>
      <el-tag v-if="form.status == 7">已上课</el-tag>
      <span slot="footer" class="dialog-footer" v-if="mode == 'add'">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-if="mode == 'edit'">
        <el-button @click="dialogVisible = false">取 消</el-button>

        <el-button type="primary" v-if="form.status == 1" @click="updateNeed"
          >更 新</el-button
        >
        <el-button
          type="success"
          v-if="form.status == 1"
          @click="updateNeedToHall(3)"
          >上架大厅</el-button
        >
        <el-button
          type="danger"
          v-if="form.status == 3"
          @click="updateNeedToHall(1)"
          >下架大厅</el-button
        >
        <el-button
          type="danger"
          v-if="form.status == 5"
          @click="updateNeedToHall(1)"
          >取消订单</el-button
        >
      </span>
    </el-dialog>

    <classEdit @success="getList(1)" ref="student"></classEdit>
  </div>
</template>

<script>
import classEdit from "@/components/classEdit/classEdit.vue";
import mechanismSelect from "@/components/select/mechanismSelect.vue";
import coachSelect from "@/components/select/coachSelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  props: ["mechanism_id", "type"],
  components: {
    mechanismSelect,
    coachSelect,
    classEdit,
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "班级名称", value: "name" },
        { name: "学生数量", value: "student_count", width: "100px" },
        {
          name: "",
          value: "student",
          type: "custom",
          col_type: "expand",
          width: "30px",
        },
        { name: "开班人数", value: "student_count_max" },
        { name: "类型", value: "type" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      curClass: {},

      dialogVisible: false,
      totalPrice: 0,
      form: {},
      settlement_type: 1,
      cityoptions: [],
      cityList: [],
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      coach_id: "",
      coachInfo: {},
      curCourse: {},
      originTemp: {},
      mode: "add",
      change_mechanism: "",
      priceInfo:'',
      managerInfo:JSON.parse(localStorage.getItem('managerInfo'))
    };
  },
  mounted() {
    this.cityoptions = JSON.parse(localStorage.getItem("addrList"));
  },

  methods: {
    toUserDetails(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //取消班级
    cancelClass(row) {
      this.$confirm(`确定取消班级：${row.name}吗，删除班级的所有学员`).then(
        () => {
          this.$axios({
            url: "/user/mechanismClasses/cancelClass",
            method: "post",
            data: {
              id: row.id,
              user_id: row.create_user,
            },
          }).then((res) => {
            this.$notify({
              type: "success",
              message: "班级已解散",
              duration: 3000,
            });
            this.getList(1);
          });
        }
      );
    },
    studentMange(row) {
      this.$refs.student.load(row);
    },
    //更新需求
    updateNeed() {
      if (!this.checkInfo()) {
        return;
      }
      this.connect_peoplenum = this.curClass.student_count;
      this.form.coach_id = this.coach_id || 0;
      this.form.settlement_type = this.settlement_type;
      const data = ObjectChangedValue(this.originTemp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      if (data.coach_id || data.mechanism_id) {
        data.status = 5;
      }
      this.$axios({
        url: "/user/userCourseNeed/update",
        method: "post",
        data: {
          ...data,
          id: this.form.id,
          class_id: this.form.class_id,
        },
      }).then((res) => {
        this.$notify({
          type: "success",
          message: "更新成功",
          duration: 3000,
        });
        this.from = {};
        this.dialogVisible = false;
        this.getList(1);
        this.$refs.mec.clear();
        this.$refs.coach.clear();
        this.coach_id = "";
      });
    },
    // 发布到大厅
    updateNeedToHall(status) {
      this.$axios({
        url: "/user/userCourseNeed/update",
        method: "post",
        data: {
          id: this.form.id,
          status,
        },
      }).then((res) => {
        this.$notify({
          type: "success",
          message: "更新成功",
          duration: 3000,
        });
        this.from = {};
        this.dialogVisible = false;
        this.getList(1);
      });
    },

    //生成班级的课程需求
    submitForm() {
      if (!this.checkInfo()) {
        return;
      }

      let url = "/user/userCourseNeed/createCommunityNeed";
      let data = {
        ...this.form,
        type: "order",
        class_id: this.curClass.id,
        settlement_type: this.settlement_type,
        status: 3,
        course_id: this.curClass.master_set_price_id,
        coach_id: this.coach_id || null,
        connect_peoplenum: this.curClass.student_count,
        admin_id:this.managerInfo.id
      };

      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        this.$notify({
          type: "success",
          message: "添加成功",
          duration: 3000,
        });
        this.from = {};
        this.dialogVisible = false;
        this.getList(1);
        this.$refs.mec.clear();
        this.$refs.coach.clear();
        this.coach_id = "";
      });
    },

    editCom(row) {
      this.mode = "edit";
      this.curClass = row;
      this.$axios({
        url: "/user/userCourseNeed/findById",
        params: {
          id: row.need_id,
        },
      }).then((res) => {
        let info = res.data.data;
        this.form = info;
        this.settlement_type = info.settlement_type;
        this.originTemp = Object.assign({}, info);
        this.cityList.push(info.province);
        this.cityList.push(info.city);
        this.cityList.push(info.district);
        this.coach_id = info.coach_id;
        this.dialogVisible = true;
      });
    },
    // 选择场馆
    changeMec(v) {
      this.$set(this.form, "province", v.province);
      this.$set(this.form, "city", v.city);
      this.$set(this.form, "district", v.district);
      this.$set(this.form, "address", v.mechanism_addr);

      this.cityList = [v.province, v.city, v.district];
    },
    //创建需求
    createCom(row) {
      // if (row.student_count == 0) {
      //   this.$message("班级内没有学员");
      //   return;
      // }
      this.form = {};
      this.mode = "add";
      this.dialogVisible = true;
      this.curClass = row;
      if (row.class_start_time.split(",")[1]) {
        this.$set(this.form, "time_slot", row.class_start_time.split(",")[1]);
      }

      this.getCourseInfo();
    },

    getCourseInfo() {
      this.$axios({
        url: "/user/masterSetPrice/findById",
        params: {
          id: this.curClass.master_set_price_id,
        },
      }).then((res) => {
        const v = res.data.data;
        this.curCourse = v;
        this.$set(this.form, "title", v.title);
        this.$set(this.form, "province", v.province);
        this.$set(this.form, "city", v.city);
        this.$set(this.form, "district", v.district);
         this.$set(this.form, "latitude", v.latitude);
          this.$set(this.form, "longitude", v.longitude);
           this.$set(this.form, "address", v.venues_addr);
        this.cityList = [v.province, v.city, v.district];

       this.priceInfo = `
       <div>课程价格：${this.curCourse.title} -${this.curCourse.course_num}课节</div>
       <div>课程价格：${this.curCourse.discount_amout}/人</div>
       <div> 单节价格：${this.curCourse.discount_amout / this.curCourse.course_num}/人 </div>`
        // if (!v.is_community) {
        //   this.$message("非社区课，不需要生成需求，请正常排课核销");
        //   this.dialogVisible = false;
        // }
      });
    },

    getList(v) {
      let page = v || 1;
      if (this.mechanism_id || this.type == "manage") {
        let params = {
          currentPage: page,
          pageSize: 10,
          mechanism_id: this.mechanism_id || this.change_mechanism || null,
          class_mechanism_id:
            this.mechanism_id || this.change_mechanism || null,
          status: 2,
        };
        if (this.type == "manage") {
          params.role = "manage";
        }
        this.$axios({
          url: "/user/mechanismClasses/queryInfoList",
          params,
        }).then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
          this.DataList.forEach((item) => {
            if (item.create_user > 0) {
              item.map.studyCards.forEach((stu) => {
                if (stu.user_id == item.create_user) {
                  item.create_user_info = stu;
                }
              });
            }
          });
        });
      }
    },
    changeAddr(v) {
      this.$set(this.form, "address", v.address);
      this.form.latitude = v.addrPoint.lat;
      this.form.longitude = v.addrPoint.lng;
      this.form.province = v.province;
      this.form.city = v.city;
      this.form.district = v.district;
      this.cityList = [v.province, v.city, v.district];
    },
    endtimeChange() {
      let s = new Date(this.form.start_time);
      let e = new Date(this.form.end_time);
      if (s >= e) {
        this.$message({ message: "结束时间不能小于开始时间" });
        this.form.end_time = "";
      }
    },
    cityChange(v) {
      this.form.province = v[0];
      this.form.city = v[1];
      this.form.district = v[2];
    },
    changeCoach(v) {
      this.coachInfo = v;
    },
    checkInfo() {
      let res = true;
      if(!this.form.amount){
          res = false;
          this.$message("请填写订单总价");
      }
      if (this.settlement_type == 2) {
        if (this.form.coach_profit > 1 || this.form.mechanism_profit > 1) {
          res = false;
          this.$message("结算比例不能大于1");
        }
      }
      return res;
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 13px;
  color: rgb(163, 161, 161);
  line-height: 2;
}

.students {
  flex-wrap: wrap;
}
.stu-item {
  margin-left: 20px;
  margin-top: 10px;
  .stu-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .stu-name {
    font-size: 12px;
  }
}
.stu-item:hover {
  cursor: pointer;
}
</style>