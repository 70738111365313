<template>
  <!-- 机构班级编辑 -->
  <div>
    <el-dialog title="学员管理" :visible.sync="dialogVisible" width="50%">
      <div class="mode">
        <el-radio-group v-model="mode" @input="modeChange">
          <el-radio label="add">添加学员</el-radio>
          <el-radio label="del">删除学员</el-radio>
        </el-radio-group>
      </div>
      <el-form :inline="true">
        <el-form-item label="上课人">
          <el-input v-model="query.name" placeholder="上课人姓名"></el-input>
        </el-form-item>
        <el-form-item label="上课人年龄">
          <el-input
            type="number"
            v-model="query.birth"
            placeholder="多少岁以下"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="DataList"
        tooltip-effect="dark"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
      >
        <el-table-column :reserve-selection="true" type="selection" width="55">
        </el-table-column>
        <el-table-column label="头像">
          <template slot-scope="{ row }">
            <img :src="row.avatar" class="avatar" />
          </template>
        </el-table-column>
        <el-table-column prop="nick_name" label="昵称">
          <template slot-scope="{ row }">
            <div>
              {{ row.student_name || row.nick_name }}
            </div>
          </template>
        </el-table-column>
          <el-table-column prop="course_num" label="剩余课程节数">
        </el-table-column>
        <el-table-column label="生日">
          <template slot-scope="{ row }">
            <div>
              {{ row.birth ? row.birth.split(" ")[0] : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="购买时间"> </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="changePage"
        :current-page.sync="currentpage"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="mode == 'add'" @click="addClassStudent"
          >添加学员</el-button
        >
        <el-button type="primary" v-if="mode == 'del'" @click="delClassStudent"
          >删除学员</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachLogoutVue from '../../views/coachManage/coachLogout.vue';
export default {
  name: "classEdit",

  data() {
    return {
      mode: "add",
      mecClass: {},
      allData: [],
      dialogVisible: false,
      DataList: [],
      studycard_id: "",
      changeList: [],
      total: 0,
      currentpage: 1,
      query: {},
    };
  },

  mounted() {},

  methods: {
    search() {
      let list = this.allData.filter((item) => {
        let has_name = true;
        let has_birth = true;
        if (this.query.name) {
          has_name = item.student_name.indexOf(this.query.name) > -1 || item.nick_name.indexOf(this.query.name) > -1;
        }
        if (this.query.birth) {
          if (item.birth) {
            has_birth =
              new Date(item.birth).getTime() > new Date().getTime() - 31536000000 * this.query.birth;
          }
        }
        return has_name && has_birth;
      });

      this.DataList = list;
      this.total = list.length;
    },
    reset() {
       this.$refs.multipleTable.clearSelection();
      this.query = {};
      this.total = this.allData.length;
      this.DataList = this.allData.slice(0, 10);
    },
    getRowKeys(row) {
      return row.id;
    },
    delClassStudent() {
      let ids = this.changeList.map((item) => item.id);
      if (ids.length == 0) {
        this.$message("请选择学员");
        return;
      }
      this.$axios({
        url: "/user/mechanismClasses/delByStudycardIds",
        method: "post",
        data: {
          id: this.mecClass.id,
          userStudyCard_ids: ids.join(","),
        },
      }).then((res) => {
        this.$refs.multipleTable.clearSelection();
        this.dialogVisible = false;
        this.$emit("success", res);
      });
    },
    addClassStudent() {
      let ids = this.changeList.map((item) => item.id);
      if (ids.length == 0) {
        this.$message("请选择学员");
        return;
      }
      this.$axios({
        url: "/user/mechanismClasses/addByStudycardIds",
        method: "post",
        data: {
          id: this.mecClass.id,
          userStudyCard_ids: ids.join(","),
        },
      }).then((res) => {
        this.$refs.multipleTable.clearSelection();
        this.dialogVisible = false;
        this.$emit("success", res);
      });
    },
    handleSelectionChange(val) {
      this.changeList = val;
    },
    load(row) {
      this.allData = [];
      this.DataList = [];
      this.mode = "add";
      this.mecClass = row;
      this.dialogVisible = true;
      this.studycard_id = row.master_set_price_id;
      this.getList();
    },
    changePage(v) {
      let s = (this.currentpage - 1) * 10;
      let e = this.currentpage * 10;
      this.DataList = this.allData.slice(s, e);
    },
    modeChange(v) {
      this.$refs.multipleTable.clearSelection();
      this.query = {}
      this.currentpage = 1;
      switch (v) {
        case "add":
          this.getList();
          break;
        case "del":
          this.getClassStudent();
          break;
        default:
          break;
      }
    },
    //已分班学员
    getClassStudent() {
      this.$axios({
        url: "/user/userStudyCard/queryInfo",
        params: {
          mechanism_class_id: this.mecClass.id,
        },
      }).then((res) => {
        this.allData = res.data.data;
        this.allData.forEach((item) => {
          if (item.map.children) {
            item.student_name = item.map.children.name;
            item.avatar = item.map.children.avatar || item.avatar;
          } else {
            item.student_name = "";
            item.birth = "";
          }
        });
        this.total = this.allData.length;
        this.DataList = res.data.data.slice(0, 10);
      });
    },
    //未分班学员
    getList() {
      this.$axios({
        url: "/user/userStudyCard/queryInfo",
        params: {
          studycard_id: this.studycard_id,
          mechanism_class_id: 0,
          type: "mechanism_offline"
        },
      }).then((res) => {
        this.allData = res.data.data;
        this.allData.forEach((item) => {
          if (item.map.children) {
            item.student_name = item.map.children.name;
            item.birth = item.map.children.birth;
            item.avatar = item.map.children.avatar || item.avatar;
          } else {
            item.student_name = "";
            item.birth = "";
          }
        });
        this.total = this.allData.length;
        this.DataList = res.data.data.slice(0, 10);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mode {
  margin-bottom: 10px;
}
.avatar {
  width: 50px;
  height: 50px;
}
</style>